
import { defineComponent } from 'vue'

import PostView from './components/PostView.vue'
import FieldView from './components/FieldView.vue'

export default defineComponent({
  name: 'App',
  components: {
    PostView,
    FieldView,
  },
  data: () => {
    return {
      posts: [] as string[]
    }
  }
});
